<template>
    <div ref="editorCellRef" class="o365-body-cell o365-editor-cell" :class="editorClass" :style="style">
        <ErrorRenderer v-if="capturedError" />
        <component v-else-if="editor.isSlot" :is="editor.node"
            :modelValue="row" :row="row" 
            :column="column" 
            :class="column.editorClass" 
            ref="editorRef"
            v-bind="column.cellEditorParams">
        </component>
        <component v-else :is="editor.node"
            v-model="row[column.field]"
            
            :class="column.editorClass"
            ref="editorRef"
            v-bind="column.cellEditorParams, conditionalProps">
        </component>
    </div>

    <Teleport :to="cellContainer">
        <div ref="editorAnchorRef" class="grid-editor-anchor"
            :style="anchorStyle"></div>
    </Teleport>
</template>
<script setup lang="ts">
import type DataColumns from 'o365.controls.DataGrid.DataColumns.ts';
import type DataGridControl from 'o365.controls.DataGrid.ts';
import { dataGridEditorCellControlKey } from 'o365.modules.vue.injectionKeys.js';
import { inject, provide, computed, ref, Teleport, resolveComponent, onMounted, onUpdated, onBeforeUnmount } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
import { createPopper } from 'popper';

// TODO: Remove after checking its safe
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor } from 'o365.vue.components.inputEditors.jsx';
const InputEditors = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor };

const props = defineProps<{
    data?: any[],
    dataColumns?: DataColumns,
    activeCell?: string,
    gridContainer?: HTMLElement,
    dataGridControl: DataGridControl,
}>();

const resolveComponentFromApp = inject('resolveComponent', null);

const expandedPopup = ref(false);
function activatePopupMode() {
    expandedPopup.value = true;
}
function deactivatePopupMode() {
    expandedPopup.value = false;

}
provide(dataGridEditorCellControlKey, {
    expandedPopup,
    activatePopupMode,
    deactivatePopupMode
});
provide('is-in-grid-cell', true);

const editorCellRef = ref(null);
const editorAnchorRef = ref(null);

const colIndex = computed(() => {
    return parseInt(props.activeCell?.split?.('_')?.[0]??'');
});
const rowIndex = computed(() => {
    return parseInt(props.activeCell?.split('_')?.[1]??'');
});

const column = computed(() => {
    return props.dataColumns.columns[colIndex.value];
});

const row = computed(() => {
    return props.data[rowIndex.value] ?? {};
});

const cellContainer = computed(() => {
    return getCellContainer(column.value.pinned);
});

function getCellContainer(pinned) {
    switch (pinned) {
        case 'left':
            return props.gridContainer?.querySelector('.o365-body-left-pinned-cols');
        case 'right':
            return props.gridContainer?.querySelector('.o365-body-right-pinned-cols');
        default:
            return props.gridContainer?.querySelector('.o365-body-center-cols-container');
    }
};

const editorClass = computed(() => {
    const classes = [{
        'o365-editor-popup': expandedPopup.value,
        'p-2': expandedPopup.value,
        'rounded-0': expandedPopup.value,
        'card': expandedPopup.value,
        'shadow': expandedPopup.value,
        //'o365-cell-range-single-cell': !expandedPopup.value
    }];
    if (column.value.cellClass) {
        classes.push(column.value.cellClass);
    }
    return classes;
});

const style = computed(() => {
    const rowIndex = parseInt(props.activeCell.split('_')[1]);
    //const pos = rowIndex * 34;
    //const pos = props.dataGridControl.virtualScrollApi.getPosByIndex(rowIndex);
    const height = props.dataGridControl.virtualScrollApi.getRowHeightByIndex(rowIndex);
    return {
        'min-width': expandedPopup.value ? '400px' : undefined,
        'max-height': expandedPopup.value ? '400px' : undefined,
        'overflow-y': expandedPopup.value ? 'auto' : undefined,
        'position': 'absolute',
        'width': column.value.width - 1 + column.value.widthAdjustment + 'px',
        //'left': column.value.left + 'px',
        'height': expandedPopup.value ? 'auto' : `${height}px`,
        //'transform': `translateY(${pos}px)`,
    }
});

const anchorStyle = computed(() => {
    const rowIndex = parseInt(props.activeCell.split('_')[1]);
    const pos = props.dataGridControl.virtualScrollApi.getPosByIndex(rowIndex);

    return {
        'position': 'absolute',
        'left': column.value.left + 'px',
        'transform': `translateY(${pos}px)`,
    }

});

const editor = computed(() => {
    deactivatePopupMode();
    if (column.value.cellEditorSlot) {
        return { node: column.value.cellEditorSlot, isSlot: true };
    } else {
        if (typeof column.value.cellEditor === 'string') {
            let editor = InputEditors[column.value.cellEditor];
            let node = editor ?? resolveComponentFromApp(column.value.cellEditor) ?? resolveComponent(column.value.cellEditor);
            return { node: node, isSlot: false };
        } else {
            return { node: column.value.cellEditor, isSlot: false };
        }
    }
});

const editorProps = computed(() => {
    if (typeof editor.value === 'object' && editor.value?.node?.props) {
        if (Array.isArray(editor.value.node.props)) {
            return editor.value.node.props;
        } else {
            return Object.keys(editor.value.node.props);
        }
    } else {
        return [];
    }
});

const conditionalProps = computed(() => {
    const obj: any = {};
    if (editorProps.value) {    
        if (editorProps .value.includes('row')) {
            obj.row = row.value;
        }
        if (editorProps .value.includes('column')) {
            obj.column = column.value;
        }
    }
    return obj;
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render column editor: ${column.value?.colId}`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'An error has occured when trying to render this cell editor'
    }
});

let popperInstance = null;
onMounted(() => {
    popperInstance = createPopper(editorAnchorRef.value, editorCellRef.value, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'flip',
                enabled: true,
                options: {
                    flipVariations: false,
                    fallbackPlacements: ['top-start']
                }
            },
            {
                name: 'offset',
                options: {
                    offset: ({placement}) => {
                        if (placement === 'top-start') {
                            return [0, -33];
                        } else {
                            return [];
                        }
                    }
                }
            },
            {
                name: 'preventOverflow',
                options: {
                    mainAxis: false, // true by default
                },
            },
        ],
    });
});

let count_u = 0;
onUpdated(() => {
    //targetCell = getCellElement();
    popperInstance.state.elements.reference = editorAnchorRef.value;
    popperInstance.update();
});

onBeforeUnmount(() => {
    popperInstance.destroy();
});

</script>